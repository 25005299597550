import ComingSoon from "../../ComingSoon";

const Messages = () => {
    return (
        <div>
            <ComingSoon />
        </div>
    )
}

export default Messages
