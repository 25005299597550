import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../Header";
import { BiCheckCircle, BiXCircle } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { GetSingleTopic } from "../../AdminControllers";
import useGetToken from "../../../../utils/useGetToken";

interface GradeState {
  grade_id: string | null;
}

function LessonDetailsAdmin() {
  const { token } = useGetToken();
  const route = useNavigate();
  const [subjectState, setSubjectState] = useState<any>(null);
  const [topics, setTopics] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedGradeState, setSelectedGradeState] = useState<GradeState>({
    grade_id: null
  });

  const { id } = useParams();

  useEffect(() => {
    const subject = Cookies.get("selectedSubject");
    const grades = Cookies.get("selectedGrade");
    if (subject) {
      setSubjectState(JSON.parse(subject));
    }
    if (grades) {
      setSelectedGradeState(JSON.parse(grades));
    }
  }, []);

  console.log(selectedGradeState.grade_id);

  useEffect(() => {
    const fetchTopicDetails = async () => {
      if (subjectState?.subject_id) {
        try {
          const data = await GetSingleTopic(id, token);
          setTopics(data);
          setLoading(false);
        } catch (error: any) {
          console.error(error.message);
          setLoading(false);
        }
      }
    };
    if (subjectState) {
      fetchTopicDetails();
    }
  }, [subjectState]);

  const handleRouting = (subject_name: any, id: any, title: any) => {
    route(`/admin/${subject_name}/${id}/${title}/video`);
  };

  const handleRoutToSubject = () => {
    route(
      `/admin/subjects/${subjectState?.subject_name}/${selectedGradeState.grade_id}`
    );
  };

  const handleDownLoadFile = () => {
    const blobUrl = topics?.file;
    const fileName = blobUrl?.split("/").pop();
    const link = document.createElement("a");
    link.style.display = "none";
    document.body.appendChild(link);
    link.href = blobUrl;
    link.download = fileName;
    link.setAttribute("target", "_blank");
    link.click();
    window.URL.revokeObjectURL(blobUrl);
    document.body.removeChild(link);
  };

  if (loading) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        Loading...
      </div>
    );
  }

  return (
    <div>
      <Header headerName={`Week ${topics?.week} - ${topics?.title}`} />
      <div className="lg:px-10 px-5 py-5">
        <div className="flex flex-wrap gap-2 text-blue-500 text-sm list-none [&>*]:self-center">
          <li onClick={handleRoutToSubject} className="cursor-pointer">
            {subjectState?.subject_name} &gt;&nbsp;
          </li>
          <li className="text-black">
            Week {topics?.week} - {topics?.title} &gt;&nbsp;
          </li>
          {/* <li className="text-black">Module</li> */}
        </div>
        <div className="py-5 space-y-3">
          <h3 className="font-semibold">Module Details</h3>
          <p className="text-sm lg:text-md lg:leading-[2rem]">
            {topics?.introduction}
          </p>
        </div>
        <div className="py-5 space-y-4">
          <h3 className="font-semibold">Module Materials</h3>
          <div className="space-y-5">
            {topics?.video ? (
              <div className="flex gap-3 [&>*]:self-center ">
                <BiCheckCircle className="text-2xl" />
                <h3
                  className="text-blue-500 cursor-pointer"
                  onClick={() =>
                    handleRouting(
                      subjectState?.subject_name,
                      topics?.id,
                      topics?.title
                    )
                  }
                >
                  Video - {topics?.title}
                </h3>
              </div>
            ) : (
              <div className="flex gap-3 [&>*]:self-center ">
                <BiXCircle className="text-2xl" />
                <h3
                  className="text-red-500 cursor-pointer"
                  onClick={() =>
                    handleRouting(
                      subjectState?.subject_name,
                      topics?.id,
                      topics?.title
                    )
                  }
                >
                  No Video Attached
                </h3>
              </div>
            )}
          </div>
          <div className="flex gap-3 [&>*]:self-center ">
            <BiCheckCircle className="text-2xl" />
            {topics?.file ? (
              <h3
                className="text-blue-500 cursor-pointer"
                onClick={handleDownLoadFile}
              >
                File - {topics?.file?.split("/").pop()}
              </h3>
            ) : (
              <h3 className="text-blue-500">No File Attached</h3>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LessonDetailsAdmin;
