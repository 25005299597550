import ComingSoon from "../../ComingSoon";

function TeacherSettings() {
  return (
    <div>
      <ComingSoon />
    </div>
  );
}

export default TeacherSettings;
