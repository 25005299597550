import ComingSoon from "../../ComingSoon";

function Settings() {
  return (
    <div>
      <ComingSoon />
    </div>
  );
}

export default Settings;
