import React from 'react'

const Notification = () => {
    return (
        <div className={`fixed w-full h-screen bg-[]`}>
            
        </div>
    )
}

export default Notification
